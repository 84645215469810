<template>
  <b-overlay
    :show="isLoading"
    rounded="lg"
    opacity="0.6"
    spinner-variant="primary"
  >
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <div class="container-fluid mt-1 pt-1">
      <div class="row">
        <h4>Update Banners</h4>
      </div>
      <div>
        <b-card class="bg-warning shadow-sm">
          <div class="row">
            <div class="col-md-1">
              <i class="fa fa-info-circle fa-4x" aria-hidden="true"></i>
            </div>
            <div class="col-md-10">
              <div class="text-light">
                <ul>
                  <li>
                    Platform IOS image banner must 2000 x 420 pixels or 1300 x
                    500 pixels
                  </li>
                  <li>Platform Android image banner must 1300 x 500 pixels</li>
                  <li>Platform WEB image banner must 2100 x 304 pixels</li>
                  <li>
                    Banner Type Promotion Banner image banner must 900 x 450
                    pixels
                  </li>
                  <li>
                    For Open URL Banner must input Payload and except Open URL
                    Banner must input Campaign
                  </li>
                  <li>Notification date must lower than Valid From</li>
                </ul>
              </div>
            </div>
          </div>
        </b-card>
      </div>
      <b-form @submit="onSubmit" @reset="reset" v-if="show">
        <div class="card">
          <div class="card-header bg-primary">
            <h5 class="card-title mb-0 font-weight-bold">General</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <b-form-group
                  id="input-bannerName"
                  label="Banner Name"
                  label-for="banner-Name"
                >
                  <b-form-input
                    id="banner-Name"
                    v-model="form.name"
                    type="text"
                    placeholder="Enter Banner Name"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group
                  id="input-platform"
                  label="Platform "
                  label-for="platform"
                >
                  <b-form-select
                    id="platform"
                    v-model="form.platform"
                    :options="platforms"
                    required
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <v-date-picker
                  v-model="form.valid_from"
                  mode="dateTime"
                  :timezone="timezone"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <b-form-group
                      id="input-validFrom"
                      label="Valid From"
                      label-for="ValidFrom"
                    >
                      <input
                        id="ValidFrom"
                        class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                        :value="inputValue"
                        autocomplete="off"
                        v-on="inputEvents"
                      />
                    </b-form-group>
                  </template>
                </v-date-picker>
              </div>
              <div class="col-md-3">
                <v-date-picker
                  v-model="form.valid_to"
                  mode="dateTime"
                  :timezone="timezone"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <b-form-group
                      id="input-validTo"
                      label="Valid To"
                      label-for="ValidTo"
                    >
                      <input
                        id="validTo"
                        class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                        :value="inputValue"
                        autocomplete="off"
                        v-on="inputEvents"
                      />
                    </b-form-group>
                  </template>
                </v-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header bg-primary">
            <h5 class="card-title mb-0 font-weight-bold">Detail</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <b-form-group
                  id="input-bannerType"
                  label="Banner Type"
                  label-for="bannerTypes"
                >
                  <b-form-select
                    id="bannerType"
                    v-model="form.bannerType"
                    :options="bannerTypes"
                    required
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group
                  id="input-bannerPlayload"
                  label="Banner Payload"
                  label-for="banner-Playload"
                  v-if="form.bannerType == 'open url'"
                >
                  <b-form-input
                    id="banner-Playload"
                    v-model="form.payload"
                    type="text"
                    placeholder="Enter Banner Payload"
                    @change="form.campaign = ''"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Campaign"
                  v-else-if="form.bannerType != 'open url'"
                >
                  <multiselect
                    v-model="form.campaign"
                    label="name"
                    track-by="name"
                    placeholder="Type Campaign"
                    open-direction="bottom"
                    :options="campaignSearchOptions"
                    :searchable="true"
                    :loading="isCampaignSearch"
                    :close-on-select="true"
                    :options-limit="10"
                    :multiple="false"
                    @change="form.payload = ''"
                    @search-change="campaignFind"
                  >
                  </multiselect>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group
                  id="input-zone"
                  label="Display Zone"
                  label-for="zone"
                >
                  <b-form-select
                    id="displayZone"
                    v-model="form.displayZone"
                    :options="displayZones"
                    required
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group
                  id="input-notificationTitle"
                  label="Notification Title"
                  label-for="notification-Title"
                >
                  <b-form-input
                    id="notification-Title"
                    v-model="form.notifation"
                    type="text"
                    placeholder="Enter Notification Title"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group
                  id="input-displayPrice"
                  label="Display Price"
                  label-for="displayPric"
                >
                  <b-form-select
                    id="displayPrice"
                    v-model="form.displayPrice"
                    :options="displayPrices"
                    required
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <v-date-picker
                  v-model="form.notifDisplayDate"
                  mode="dateTime"
                  :timezone="timezone"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <b-form-group
                      id="input-notifDisplayDate"
                      label="Notification Display Date"
                      label-for="notifDisplayDate"
                    >
                      <input
                        id="notifDisplayDate"
                        class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                        :value="inputValue"
                        autocomplete="off"
                        v-on="inputEvents"
                      />
                    </b-form-group>
                  </template>
                </v-date-picker>
              </div>
              <div class="col-md-6">
                <b-form-group
                  id="input-termCondition"
                  label="Banner terms and condition"
                  label-for="termCondition"
                >
                  <b-form-textarea
                    id="termCondition"
                    v-model="form.termCondition"
                    placeholder="Enter Term Condition"
                    rows="12"
                    required
                  >
                  </b-form-textarea>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group
                  id="input-notifContent"
                  label="Notification Content"
                  label-for="notifContent"
                >
                  <b-form-textarea
                    id="notifContent"
                    v-model="form.notifContent"
                    placeholder="Enter Notification Content"
                    rows="12"
                    required
                  >
                  </b-form-textarea>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <b-form-group
                  id="input-sortPriority"
                  label="Sort Priority"
                  label-for="sortPriority"
                >
                  <b-form-input
                    id="sortPriority"
                    v-model.number="form.sortPriority"
                    type="number"
                    step="any"
                    placeholder="10"
                    :min="10"
                    :max="50000"
                    required
                  ></b-form-input>
                  <small>*Please input between 10 - 50000</small>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header bg-primary">
            <h5 class="card-title mb-0 font-weight-bold">Banner</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <b-form-file
                v-model="form.file"
                :state="Boolean(form.file)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".jpg,.png,.jpeg"
              ></b-form-file>
              <div class="mt-3">
                Selected file: {{ form?.file?.name ?? '' }}
              </div>
            </div>
            <div class="form-group">
              <div class="card">
                <img
                  class="rounded"
                  style="width: 70%, height: 40%;"
                  :src="form.imagesrc"
                  alt="Image Banner"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 pb-3 mt-5">
                <b-button
                  class="mr-3 text-white"
                  type="submit"
                  variant="primary"
                >
                  Update Banner
                </b-button>
                <b-button
                  variant="outline-primary"
                  @click="$router.push('/banner')"
                  >Cancel</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </b-overlay>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import constant from '../../store/constant';
import moment from 'moment';

const BANNER_SHOW_TYPES = Object.entries(constant.BANNER_SHOW_TYPES).map(
  (item) => {
    return {
      text: item[0],
      value: item[1],
    };
  },
);

const BANNER_ZONE = Object.entries(constant.BANNER_ZONE).map((item) => {
  return {
    text: item[0],
    value: item[1],
  };
});

const BANNER_PRICE = Object.entries(constant.BANNER_PRICE).map((item) => {
  return {
    text: item[0],
    value: item[1],
  };
});

// BANNER_SHOW_TYPES
export default {
  name: 'editBanners',
  data() {
    return {
      form: {
        name: '',
        platform: null,
        valid_from: '',
        valid_to: '',
        time_valid_from: '',
        time_valid_to: '',
        notif_time: '',
        bannerType: null,
        payload: '',
        displayZone: null,
        notifation: '',
        displayPrice: null,
        notifDisplayDate: '',
        termCondition: '',
        notifContent: '',
        file: null,
        campaign: '',
        imagesrc: '',
        sortPriority: 10,
      },
      timezone: 'Asia/Bangkok',
      platforms: [
        { value: null, text: 'Select Platforms' },
        { value: 1, text: 'IOS' },
        { value: 2, text: 'Android' },
        { value: 7, text: 'Web Store' },
      ],
      bannerTypes: [
        { value: null, text: 'Select Banner Type' },
        ...BANNER_SHOW_TYPES,
      ],
      displayZones: [
        { value: null, text: 'Select Display Zone' },
        ...BANNER_ZONE,
      ],
      displayPrices: [
        { value: null, text: 'Select Display Prices' },
        ...BANNER_PRICE,
      ],
      show: true,
      campaignSearchOptions: [],
      isCampaignSearch: false,
      debounce: null,
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.banners.isError,
      isLoading: (state) => state.banners.isLoading,
      errorMessage: (state) => state.banners.errorMessage,
      successMessage: (state) => state.banners.successMessage,
      item: (state) => state.banners.item,
    }),
  },
  watch: {
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage: function() {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);
      this.$router.push({
        path: `/banner`,
      });
    },
    item: function() {
      if (!Object.keys(this.item).length) return;
      this.setEditBanners();
    },
  },
  created: function() {
    let id = this.$route.params.id;
    this.item_id = id;
    this.fetchNotifBannersByid(id);
  },
  methods: {
    ...mapActions('campaign', ['searchCampaign']),
    ...mapActions('banners', [
      'bannerPost',
      'fetchBannersByid',
      'fetchCampaignById',
      'fetchNotifBannersByid',
      'bannerUpdate',
      'fetchCampaignId',
    ]),

    onSubmit(event) {
      let id = this.$route.params.id;
      event.preventDefault();
      const data = this.form;
      const payload = {
        id: id,
        client_id: data.platform,
        name: data.name,
        show_price: data.displayPrice,
        zone: data.displayZone,
        banner_type: data.bannerType,
        payload: data.payload,
        campaign_id: data.campaign,
        term_and_condition: data.termCondition,
        image_file: data.file,
        title: data.notifation,
        content: data.notifContent,
        notice_date: data.notifDisplayDate,
        sortPriority: data.sortPriority,
      };
      payload.valid_from = data.valid_from
        ? new Date(data.valid_from).toISOString()
        : null;
      payload.valid_to = data.valid_to
        ? new Date(data.valid_to).toISOString()
        : null;
      payload.notice_date = data.notifDisplayDate
        ? new Date(data.notifDisplayDate).toISOString()
        : null;
      this.bannerUpdate(payload);
    },
    campaignFind(query) {
      if (!query) return;

      this.isCampaignSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
        };
        this.searchCampaign(payload)
          .then((response) => {
            this.campaignSearchOptions = response.data.data.rows;
            this.isCampaignSearch = false;
          })
          .catch(() => {
            this.isCampaignSearch = false;
          });
      }, 600);
    },
    containsOnlyNumbers(str) {
      return /^\d+$/.test(str);
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },

    setEditBanners() {
      const data = this.item;
      this.form.name = data.name;
      this.form.platform = data.client_id[0];
      this.form.valid_from = moment(data.valid_from).format(
        'MM/DD/YYYY h:mm A',
      );
      this.form.valid_to = moment(data.valid_to).format('MM/DD/YYYY h:mm A');
      this.form.bannerType = data.banner_type;
      this.form.displayZone = data.zone;
      this.form.displayPrice = data.show_price;
      this.form.termCondition = data.term_and_condition;
      this.form.sortPriority = data.sort_priority;
      if (this.containsOnlyNumbers(data.payload)) {
        this.fetchCampaignId(data.payload).then((response) => {
          this.form.campaign = response?.data?.data?.rows[0];
          this.campaignSearchOptions = response?.data?.data?.rows;
        });
      } else {
        this.form.payload = data.payload;
      }
      if (data?.notifications_banner) {
        this.form.notifation = data.notifications_banner.title;
        this.form.notifDisplayDate = moment(
          data.notifications_banner.notice_date,
        ).format('MM/DD/YYYY h:mm A');
        this.form.notifContent = data.notifications_banner.content;
      }
      this.form.imagesrc =
        data.image_normal == 'Unknown.jpg'
          ? null
          : constant.IMG_URL_STATIC_BANNER.URL + data.image_normal;
    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
  },
};
</script>
